
import marked from "marked";
import DOMPurify from "dompurify";
export default {
  props: {
    markdown: {
      type: String,
      default: ""
    }
  },
  computed: {
    markdownToHtml() {
      let output = DOMPurify.sanitize(marked(this.markdown));
      this.$emit("html", output);
      return output;
    }
  }
};
