
import FaqEditItem from "@/components/FaqEditItem.vue";

export default {
  components: { FaqEditItem },
  data() {
    return {
      breadcrumbs: [{ title: "Faqs", link: "/faqs" }]
    };
  }
};
