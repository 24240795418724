
import { mapState } from "vuex";

import BaseTextArea from "./BaseTextArea.vue";
import BaseConfirmModal from "@/components/BaseConfirmModal.vue";
import RenderMarkdown from "@/components/RenderMarkdown.vue";
import FaqEditItemInstructions from "@/components/FaqEditItemInstructions.vue";

export default {
  components: {
    BaseTextArea,
    BaseConfirmModal,
    RenderMarkdown,
    FaqEditItemInstructions
  },

  props: {
    faqId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      faq: {},
      confirmDelete: false
    };
  },
  created() {
    this.faq = this.loadFaq(this.faqId);
  },
  computed: {
    ...mapState("faqs", ["list", "newFaq"])
  },
  methods: {
    loadFaq(id) {
      let found = this.list.find(item => item.id === id);
      return Object.assign({}, found || this.newFaq);
    },
    startSave() {
      this.saveFaq(this.faq);
    },
    async saveFaq(faq) {
      console.log("saving faq with html", faq.answerHtml);
      if (faq.question && faq.answer && faq.answerHtml) {
        if (faq.id) {
          // console.log("Saving faq", faq);
          await this.$store.dispatch("faqs/updateFaq", faq);
        } else {
          faq.sequence = this.list.length;
          await this.$store.dispatch("faqs/createFaq", faq);
        }
        this.$router.push("/faqs");
      } else {
        console.log("Missing something to close");
      }
    },
    close() {
      this.$router.push("/faqs");
    },
    updateHtml(data) {
      // console.log("Updating this.faq.answerHtml", this.faq.answerHtml);
      this.faq.answerHtml = data;
      // console.log("new answerHtml", data);
    }
  },
  async deleteFaq(faq) {
    await this.$store.dispatch("faqs/deleteFaq", faq);
    confirmDelete = false;
    this.$router.push("/faqs");
  }
};
