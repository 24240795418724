
import { mapState } from "vuex";
import draggable from "vuedraggable";
import FaqListItem from "@/components/FaqListItem.vue";
export default {
  components: {
    draggable,
    FaqListItem
  },
  props: {},
  data() {
    return {
      loadError: ""
    };
  },
  computed: {
    ...mapState("faqs", ["list"])
  },
  async created() {
    await this.$store.dispatch("faqs/getAll");
  },
  methods: {
    async saveSequence() {
      let index = 0;

      for (let faq of this.list) {
        faq.sequence = index++;
        await this.$store.dispatch("faqs/updateFaq", faq);
      }
    }
  }
};
