
import BaseIcon from "@/components/BaseIcon.vue";
import RenderMarkdown from "@/components/RenderMarkdown.vue";

export default {
  components: {
    BaseIcon,
    RenderMarkdown
  },

  props: {
    faq: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      confirmDelete: false
    };
  }
};
